<template>
  <div class="video-page">
    <div class="video-body">
        <h1>{{ obj.title }}</h1>
        <div class="base-info">
            <span>{{ obj.createTime }}</span>
        </div>
        <!-- <video src="https://resources.holowits.com.sg/material/570c69b4b4f64f7e86ab2f9d43814e71.mp4"></video> -->
        <template v-if="(obj.url).includes('www.youtube.com')">
            <iframe class="video" :src="obj.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template v-else>
            <img class="video" :src="obj.url" alt="">
        </template>
        <div class="describe">
            <p>{{ obj.describe }}</p>
        </div>
        <div class="section-title">{{ $i18n.t('training.documentation') }}</div>
        <div class="documents">
            <div class="document" v-for="(item) in docs" :key="item.id">
                <div class="resouse-name">{{ item.title }}</div>
                <div class="resouse-link" @click="download(item.url)">{{ $i18n.t('productDetail.clicktoLoad' )}}</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {checkCsp} from '@/api/userApi'
import {getData, getDocByVideoId, updateUserSchedule, getUserScheduleByInfo} from '@/api/cspVideoApi'
export default {
    name: 'CspVideo',
    data() {
        return {
            videoId: '',
            obj: {
                videoUrl: ''
            },
            docs: [],
            browseTime: 0,  // 浏览时长初始值为 0
            clearTimeSet: null,
            cumulativeDuration: 0,
        }
    },
    created() {
        let {videoId} = this.$route.params;
        this.videoId = videoId;
    },
    async mounted() {
        await this.checkAccess()
        await this.getData()
        await this.getUserScheduleByInfo()
        this.setTime();
    },
    beforeDestroy() {
        // 离开页面后清除定时器
        clearInterval(this.clearTimeSet);
        // 上送后台接口，将浏览时长等信息传到后台，离开当前路由后调用
        this.updateUserSchedule();
    },
    methods: {
        async getUserScheduleByInfo() {
            const {data: {data, isSuccess}} = await getUserScheduleByInfo({
                cspVideoId: this.videoId,
                uid: this.$store.state.client.user.id
            })
            if (isSuccess) {
                this.cumulativeDuration = data
            }
        },
        async updateUserSchedule() {
            if ((this.browseTime + this.cumulativeDuration) > this.obj.playTimes) {
                this.browseTime = this.obj.playTimes;
            } else {
                this.browseTime += this.cumulativeDuration;
            }
            const {data: {data, isSuccess}} = await updateUserSchedule({
                cspVideoId: this.videoId,
                playSchedule: this.browseTime
            })
            if (!isSuccess) {
                setTimeout(() => {
                        this.updateUserSchedule()
                    },
                    2000
                )
            }
        },
        setTime() {
            //设置定时器
            this.clearTimeSet = setInterval(() => {
                this.browseTime++;
                // console.log(this.browseTime, "时长累计");
            }, 1000);
        },
        download(value) {
            window.open(value)
        },
        async getDocByVideoId() {
            const {data: {data, isSuccess}} = await getDocByVideoId({
                id: this.videoId
            })
            if (isSuccess) {
                this.docs = data;
            }
        },
        async getData() {
            const {data: {data, isSuccess}} = await getData({
                id: this.videoId
            })
            if (isSuccess) {
                this.obj = data;
                this.getDocByVideoId()
            }
        },
        tips() {
            const h = this.$createElement;
            this.$confirm('',{
                title: 'Tips',
                message: h('div', null, [
                    h('p', null, 'Please log in to your HOLOWITS Certified Specialist Exam account first.'),
                    h('p', null, 'If you do not have an account, please apply for one at '),
                    h('a', {attrs: { href: 'https://www.holowits.com/learn-register' }, style: {'color': '#d2333c'}}, 'https://www.holowits.com/learn-register'),
                ]),
                closeOnClickModal: false
            }).then(_ => {}).catch(_ => {});
        },
        async checkAccess() {
            const account = this.$store.state.client.user.account;
            if (!account) {
                this.tips();
                return false;
            }
            // 验证 csp
            const hasAccess = await this.checkCsp(account);
            if (!hasAccess) {
                this.tips();
                return false;
            }
        },
        async checkCsp(account) {
            const {data: {isSuccess, data}} = await checkCsp({account: account})
            if (isSuccess) {
                return data;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.video-page {
    height: inherit;
    padding-top: 30px;
    border-top: 1px solid rgb(230 239 243);
    .video-body {
        width: 1080px;
        margin: auto;
        h1 {
            font-size: 22px;
            line-height: 34px;
            font-weight: 500;
            margin-bottom: 6px;
        }
        .base-info {
            font-size: 13px;
            color: #AEB3B9;
            margin-bottom: 12px;
        }
        .video {
            width: 100%;
            height: 619px;
        }
        .describe {
            margin-top: 20px;
        }
        .section-title {
            height: 27px;
            line-height: 27px;
            font-size: 20px;
            color: #000;
            border-left: 6px solid #c7000b;
            padding-left: 32px;
            margin-bottom: 30px;
            margin-top: 60px;
        }
        .documents {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .document {
                width: 100%;
                height: 40px;
                padding-left: 40px;
                // padding-right: 60px;
                position: relative;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                &::before {
                    position: absolute;
                    left: 0px;
                    top: 15px;
                    content: '';
                    width: 10px;
                    height: 10px;
                    background-color: gray;
                    border-radius: 50%;
                }
                margin-bottom: 20px;
                .resouse-name {
                    font-size: 16px;
                    width: 500px;
                }
                .resouse-link {
                    cursor: pointer;
                    border: 1px solid #c7000b;
                    padding: 5px 11px 5px 11px;
                    border-radius: 224px;
                    color: #c7000b;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>