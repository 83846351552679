import axiosApi from './axiosApi.js'

export const getList = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/getList',
        data
    })
}

export const getFilterList = () => {
    return axiosApi({
        method: 'get',
        url: '/intserv/cspVideo/getFilterList',
    })
}

export const getData = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/getData',
        data
    })
}

export const search = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/search',
        data
    })
}

export const getDocByVideoId = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/getDocByVideoId',
        data
    })
}

export const updateUserSchedule = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/updateUserSchedule',
        data
    })
}

export const getUserScheduleByInfo = (data) => {
    return axiosApi({
        method: 'post',
        url: '/intserv/cspVideo/getUserScheduleByInfo',
        data
    })
}