import axiosApi from '@/api/axiosApi';

//修改用户
export const updateSomeInfo = (data) => axiosApi({
  method: 'POST',
  url: '/partner/user/updateSomeInfo',
  data,
});

export const checkCsp = (data) => axiosApi({
  method: 'POST',
  url: '/partner/user/checkCsp',
  data
})

export const getUserInfoByToken = (data) => axiosApi({
  method: 'POST',
  url: '/partner/user/findByUserId',
  data
})

export const getRolesByUser = () => axiosApi({
  method: 'GET',
  url: '/partner/role/getRolesByUser'
})
